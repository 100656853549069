let timer;
const D = $(document);
//скрипты для бэка
jQuery(document).ready(function ($) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
})

jQuery(function ($) {
    D.on('submit', '.js-form', function (e) {
        e.preventDefault();
        let form = $(this);
        let formData;
        if (form.data('method') == 'post') {
            formData = new FormData(this);
        } else {
            formData = form.serialize();
            let str = '?';
            $(this).find('input, textarea, select').each(function () {
                if (this.name && $(this).val())
                    str += this.name + '=' + $(this).val() + '&';
            });
            history.pushState({}, '', str);
        }
        sendForm(form, formData);
        return false;
    })

    /**
     * при смене категорий - выводим подкатегории
     */
    $(document).on('change', '.select-subcategory', function () {
        const $parent = $(this).closest('.choose_categories_item'),
            id = $(this).val();
        $parent.nextAll('.choose_categories_item').remove();
        $('.properties1').html('');
        $('.properties2').html('');
        myPlacemark1 = null;
        appendSubcategory(id);
    });

    $(document).on('click', '.submit-form-checkbox', function () {
        $(this).closest('form').submit();
    });

    D.on('keyup', '.price_input_field', function () {
        if ($(this).val()) {
            $('#new_announcement_step5').addClass('new_announcement_item_small_child_svg_text_wrapper_passed');

        } else {
            $('#new_announcement_step5').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed');
        }
    });

    D.on('keyup', '.address-input', function () {
        let val = $(this).val();
        if (val) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                $('#new_announcement_step3').addClass('new_announcement_item_small_child_svg_text_wrapper_passed');
                searchPoint(val);
            }, 500);
        } else {
            $('#new_announcement_step3').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed');
        }
    });

    var maxFileSize = 2 * 1024 * 1024; // (байт) Максимальный размер файла (2мб)
    var queue = {};
    var imagesList = $('#uploadImagesList');

    var itemPreviewTemplate = imagesList.find('.col0.template').clone();
    itemPreviewTemplate.removeClass('template');
    imagesList.find('.col0.template').remove();

    $('.js-add-photo').on('click', function (e) {
        e.preventDefault();
        $(this).prev('input').click();
    });

    $('#addImages').on('change', function () {
        var files = this.files;

        for (var i = 0; i < files.length; i++) {
            var file = files[i];

            if (!file.type.match(/image\/(jpeg|jpg|png|gif)/)) {
                alert('Фотография должна быть в формате jpg, png или gif');
                continue;
            }

            if (file.size > maxFileSize) {
                alert('Размер фотографии не должен превышать 2 Мб');
                continue;
            }

            preview(files[i]);
        }

        this.value = '';
    });

    // Создание превью
    function preview(file) {
        var reader = new FileReader();
        reader.addEventListener('load', function (event) {
            var img = document.createElement('img');

            var itemPreview = itemPreviewTemplate.clone();

            itemPreview.find('.photo1__wrap').css('background-image', 'url("' + event.target.result + '")');
            itemPreview.data('id', file.name);

            imagesList.append(itemPreview);

            queue[file.name] = file;

        });
        $('#new_announcement_step4').addClass('new_announcement_item_small_child_svg_text_wrapper_passed');
        reader.readAsDataURL(file);
    }

    // Удаление фотографий
    imagesList.on('click', '.photo1__remove', function (e) {
        e.preventDefault();
        var item = $(this).closest('.col0'),
            id = item.data('id');

        delete queue[id];

        item.remove();
        if (imagesList.find('.col0').length == 1)
            $('#new_announcement_step4').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed');
    });

    $('.with_images_form').on('submit', function (e) {
        e.preventDefault();
        $('.ajax-loader').show();
        $('.st-input1__error').remove();
        let form = $(this);

        var formData = new FormData(this);

        for (var id in queue) {
            formData.append('image[]', queue[id]);
        }

        sendForm(form, formData);
    });

    $('.input-ava').each(function () {
        let item = $(this),
            inp = item.find('.input-ava__input'),
            inp_remove = item.find('.input-ava__input-remove'),
            lbl = item.find('.input-ava__image'),
            lbl_html = lbl.html(),
            remove = item.find('.input-ava__remove');
        if (lbl.data('image')) {
            lbl.css('background-image', 'url("' + lbl.data('image') + '")').html('');
            remove.show();
        }
        lbl.on('click', function (e) {
            e.preventDefault();
            inp.trigger('click');
            inp.on('change', function () {
                let files = this.files;
                let reader = new FileReader();
                reader.addEventListener('load', function (event) {
                    lbl.css('background-image', 'url("' + event.target.result + '")').html('');
                });
                reader.readAsDataURL(files[0]);
                remove.show();
            });
        });
        remove.on('click', function () {
            inp_remove.val(1);
            lbl.css('background-image', '').html(lbl_html);
            remove.hide();
        });
    });

    $(document).on("click", '.search_categories_form_select_menu_first', function (e) {
        //$(".search_categories_form_select_menu_ul_main_wrapper").removeClass("open");
        let wrapper = $(this).find('.search_categories_form_select_menu_ul_main_wrapper');
        wrapper.addClass("open")
    });

    $('.search_categories_form_select_menu_first').each(function () {
        let values = $(this).find('.search_categories_form_select_menu_first_li'),
            input = $(this).find('input'),
            text = $(this).find('.search_categories_form_select_menu_first_title'),
            wrapper = $(this).find('.search_categories_form_select_menu_ul_main_wrapper');
        values.on('click', function () {
            console.log(wrapper)
            setTimeout(function () {
                wrapper.removeClass('open');
            }, 100)
            input.val($(this).data('value'))
            text.html($(this).html())
        });

    });


    $(document).mouseup(function (e) {
        var div = $('.search_categories_form_select_menu_first');
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            div.find('.search_categories_form_select_menu_ul_main_wrapper').removeClass("open");
        }
    });

});

/**
 * ищет подкатегории по id родителя, добавляет в шаблон
 * @param id
 */
function appendSubcategory(id) {
    fetch('/get_categories?id=' + id)
        .then((response) => {
            return response.text();
        })
        .then(data => {
            if (data) {
                $('.categories1').append(data);
                $('#new_announcement_step1').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed');
            } else {
                $('#new_announcement_step1').addClass('new_announcement_item_small_child_svg_text_wrapper_passed');
                viewParameters(id);
            }
        })
        .catch(error => console.log(error));
}

/**
 * выводит параметры в зависимости от родительских категорий
 */
function viewParameters(id) {
    $('.categories1 .choose_categories_item:last-child').find('input').attr('name', 'last_category');
    fetch('/get_properties?id=' + id)
        .then((response) => {
            return response.json();
        })
        .then(data => {
            if (data.prop) {
                $('.properties1').html(data.prop);
                $('#new_announcement_step2').show();
                D.on('change', '.properties1 input', function () {
                    checkPropertiesStep()
                });
            } else {
                $('#new_announcement_step2').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed').hide();
            }
            if (data.map) {
                $('.properties2').html(data.map);
                $('#new_announcement_step3').show();
            } else {
                $('#new_announcement_step3').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed').hide();
            }
            if (data.price) {
                $('.price-block').html(data.price);
            }
        })
        .catch(error => console.log(error));

}

function checkPropertiesStep() {
    let all_filled = true;
    D.find('.properties1 input').each(function () {
        if (!$(this).val())
            all_filled = false;
        console.log(all_filled);
    });
    if (all_filled)
        $('#new_announcement_step2').addClass('new_announcement_item_small_child_svg_text_wrapper_passed');
    else
        $('#new_announcement_step2').removeClass('new_announcement_item_small_child_svg_text_wrapper_passed');


}

function sendForm(form, formData) {
    $('.error_wrap').html('');
    $('.ajax-loader').show();
    $.ajax({
        url: form.data('action'),
        method: form.data('method'),
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: function (msg) {
            $('.ajax-loader').hide();
            $('[title="Close"]').click();
            console.log(msg)
            if (form.data('homepage')) {
                appendMore(msg);
            }
            if (msg.success) {
                Swal.fire({
                    icon: 'success',
                    html: msg.success
                })
            }
            if (msg.error) {
                Swal.fire({
                    icon: 'error',
                    text: msg.error
                })
            }
            if (msg.redirect) {
                $('.ajax-loader').show();
                window.location = msg.redirect;
            }

        },
        error: function (xhr) {
            $('[title="Close"]').click();
            $('.ajax-loader').hide();
            showError(xhr, form)
        }
    });
}

function showError(xhr, form) {
    $('.ajax-loader').hide();
    if (xhr.status == 422) {
        let mes = JSON.parse(xhr.responseText).errors;
        console.log(mes);
        let from_top = 0, from_top_temp;
        for (let prop in mes) {
            form.find('input, textarea, select').each(function () {
                if ($(this).attr('name') && $(this).attr('name').replace(/\[]/g, '').replace(/\[/g, '.').replace(/]/g, '') == prop) {
                    from_top_temp = addError($(this), mes[prop]);
                }
                if (!from_top || from_top > from_top_temp)
                    from_top = from_top_temp;
            })
            if (from_top)
                $('html,body').stop().animate({scrollTop: from_top}, 600);
        }
    } else if (xhr.status == 403) {
        Swal.fire({
            icon: 'error',
            text: 'Операция не разрешена'
        })
    } else {
        Swal.fire({
            icon: 'error',
            text: 'Произошла ошибка. Обновите страницу и повторите попытку'
        })
    }
}

function addError($input, value) {
    let parent = $input.closest('.input-wrapper');
    parent.find('.alert-danger').remove();
    parent.append('<div class="alert alert-danger"><p>' + value + '</p></div>');
    return parent.offset().top;
}

function searchPoint(val) {
    ymaps.geocode(val).then(function (res) {
        let coord = res.geoObjects.get(0).geometry.getCoordinates();
        if (!myPlacemark1) {
            myPlacemark1 = new ymaps.Placemark(coord, {
                iconCaption: val,
            }, {
                preset: 'islands#redDotIconWithCaption',
            });
            myMap1.geoObjects.add(myPlacemark1);
        } else {
            myPlacemark1.geometry.setCoordinates(coord)
            myPlacemark1.properties.set('iconCaption', val);
        }
        myMap1.setCenter(coord, 15);

    });
}

function appendMore(data) {
    $('.append-more-form').remove();
    $('.new_announcements_append_wrapper').append(data);
}
